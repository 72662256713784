// メディアクエリを設定

$pc: 1300px;
$tab: 1299px;
$sp: 940px;
$white-bg: #fff;
$black-bg: #282828;
$min-width: 470px;
@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (min-width: ($sp)) and ( max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

// mixin関数など
@mixin letterSpacing($fontSize, $value ) {
  letter-spacing: calc( #{$fontSize} * 0.001 * #{$value} );
}

@mixin staggered_transitions($nth:1,$items:2,$initial:0.1,$step:.1){
	@for $i from $nth through $items{
		&:nth-child(#{$i}){
			transition-delay:#{$initial}s;
		}
		$initial: $initial + $step;
	}
}

$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

// 各種読み込みファイル
@import 'reset';
@import 'grid';
@import 'fonts';
@import 'global';
@import 'header';
@import 'index';
@import 'about';
@import 'contact';
@import 'news';
@import 'works';
