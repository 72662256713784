.works {
  position: fixed;
  right: 0;
  z-index: 10;
  background: #{$black-bg};
  transform: translateX(100%);
  overflow-y: scroll;
  overscroll-behavior: none;
  @include sp {
    width: 100%;
    height: calc( 100% - 40px );
    padding-left: 7px;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), width .1s linear;
    transition-delay: .3s, 0s;
  }
  @include tab {
    width: 50%;
    height: 100%;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), width .1s linear;
    transition-delay: .3s, 0s;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    width: 50%;
    height: 100%;
    top: 51px;
    padding-left: 44px;
    padding-right: 28px;
    padding-bottom: 56px;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), width .1s linear;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
      .works__left {
        display: none;
      }
      .works__right {
        width: 100%;

        .category, .year {
          @include pc {
            display: inline !important;
          }
        }

        h2 {
          margin-bottom: 0;
        }

        &__content {
          @include tab {
            margin-top: 200px;
          }
          @include pc {
            margin-top: 200px;
          }
        }

      }
    }
  }
  &.is_active {
    transform: translateX(0);
  }

  &__threshold {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #282828;
    z-index: 1000;
    display: none;
    &__inner {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      animation: loadingWorks .75s linear infinite;
      @include sp {
        width: 80px;
        top: 50%;
        left: 50%;
        margin-top: 80px;
        margin-left: 100px;
      }
      @include tab {
        width: 100px;
        top: 50%;
        left: 50%;
        margin-top: 50px;
        margin-left: 90px;
      }
      @include pc {
        width: 100px;
        top: 50%;
        left: 50%;
        margin-top: 50px;
        margin-left: 90px;
      }
    }
  }

  &__row {
    display: flex;
  }

  &__left {
    color: #fff;
    width: calc( 25% - 32px );
    padding-top: 18px;
    @include sp {
      display: none;
    }
    @include tab {
      display: none;
    }
    p {
      word-wrap: break-word;
      word-break: break-all;
      @extend .swiss721_extend;
      @include pc {
        margin-top: -2px;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  &__left__arrow {
    @include sp {
      padding-left: 46px;
      padding-top: 22px;
      width: calc( 25% + 28px);
    }
    @include tab {
      width: calc( 25% + 28px );
      padding-left: 56px;
      padding-top: 30px;
    }
    @include pc {
      width: calc( 25% - 16px );
      padding-top: 24px;
    }
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      .cls-1{
        fill:none;
        stroke:#fff;
        stroke-miterlimit:10;
      }
    }
    svg:hover ,
    svg:active {
      .cls-1 {
        stroke: #ffff00;
      }
    }
  }

  &__right {
    color: #fff;
    position: relative;
    @include sp {
      padding-left: 56px;
      padding-top: 8px;
      width: calc( 100% - 25px );
    }
    @include tab {
      padding-left: 56px;
      padding-top: 14px;
      width: calc( 100% - 25px );
    }
    @include pc {
      width: calc( 75% + 36px );
      padding-top: 14px;
      padding-left: 14px;
    }
    h2 {
      /*
      @extend .swiss721_extend_bold;
      font-size: 27px;
      line-height: 28px;
      @include sp {
        margin: 0;
      }
      @include pc {
        margin-bottom: 169px;
        margin-top: -2px;
        @include letterSpacing(27px, 0);
      }
      */
      @extend .swiss721_extend_bold;
      transform: scaleY(0.85);
      font-size: 27px;
      line-height: 28px;
      position: absolute;
      @include sp {
        margin: 0;
        top: 42px;
      }
      @include tab {
        top: 42px;
      }
      @include pc {
        margin-bottom: 194px;
        margin-top: -2px;
        @include letterSpacing(27px, 0);
      }
    }

    .category, .year {
      @include sp {
        font-family:"Helvetica Neue LT W05_65 Medium";
        letter-spacing: 1px;
      }
      @include tab {
        font-family:"Helvetica Neue LT W05_65 Medium";
        letter-spacing: 1px;
      }
      @include pc {
        display: none;
      }
    }

    .category {
      & + br {
        @include sp {
          display: none;
        }
      }
    }

    .thumb {
      position: relative;
    }

    .thumb svg {
      width: 88.966px;
      height: 88.966px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .thumb__img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      padding: calc( 360 / 640 * 100% ) 0 0;
    }

    .thumb__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .thumb__movie__child {
      position: relative;
      width: 100%;
      margin-bottom: 28px;
      &:before {
        content:"";
        display: block;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      /*
      &.is-horizontal {
        margin-bottom: 28px;
        &:before {
          content:"";
          display: block;
          padding: calc( 360 / 640 * 100% ) 0 0;
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      &.is-vertical {
        margin-bottom: 28px;
        &:before {
          content:"";
          display: block;
          padding: calc( 16 / 9 * 100% ) 0 0;
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      */
    }

    .ja p {
    }
    .ja {
      //margin-top: -28px;
      margin-bottom: -24px;
      transform: scaleY(.85);
      transform-origin: center;
      @include sp {
        margin-top: 56px;
      }
      @include tab {
        margin-top: 56px;
      }
      @include pc {
        margin-top: 56px;
      }
    }
    .ja p a,
    .en p a {
        color: #fff !important;
        text-decoration: underline !important;
    }
    .ja p {
      margin-bottom: 56px;
      line-height: calc( 28px * 1.2 );
      @include sp {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0;
      }
      @include tab {
        margin-bottom: 0;
      }
      @include pc {
        margin-bottom: 0;
      }
    }
    .en {
      @extend .helvetica_neue_medium;
      margin-bottom: 0;
      @include sp {
        margin-top: 14px;
      }
      p {
        @include sp {
          font-size: 15px;
          @include letterSpacing(15px, 75);
        }
        @include tab {
          margin-top: 20px;
          @include letterSpacing(18px, 50);
        }
        @include pc {
          margin-top: 20px;
          @include letterSpacing(18px, 50);
        }
      }
    }

    .credit {
      @extend .Helvetica_Neue_LT_W05_55_Roman;
      margin-bottom: 112px;
      font-size: 13px;
      line-height: 21px;
      @include letterSpacing(13px, 75);
      @include sp {
        margin-bottom: 84px;
      }
    }

    &__content {
      padding-top: 200px;

      @include sp {
        //margin-top: 204px;
      }
      @include tab {
        //margin-top: 204px;
      }
    }

    &__slider {
      @include sp {
        margin: 30px auto 0;
      }
      @include tab {
        margin: 56px auto 0;
      }
      @include pc {
        margin: 56px auto 0;
      }
      &.is_remove {
        display: none;
      }
      .sp-slides {
        /*
        width: 100%;
        height: auto;
        position: relative;
        &:before {
          content: '';
          display: block;
          padding-top: 53.84%;
        }
        */
      }
      .sp-slide {
        /*
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        */
      }
      .sp-image-container {
        /*
        height: 100% !important;
        */
      }
      .sp-slide img {
        /*
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        */
      }
    }

    &__count {
      @extend .swiss721_extend;
      font-size: 21px;
      margin-top: 7px;
      &.is_remove {
        display: none;
      }
      @include sp {
        margin-bottom: 14px;
      }
      @include tab {
        margin-bottom: 25px;
      }
      @include pc {
        margin-bottom: 25px;
      }
    }

  }

  &__right__social {
    @extend .helvetica_neue_medium;
    width: calc( 75% + 10px );
    font-size: 20px;
    @include sp {
      padding-bottom: 36px;
    }
    @include tab {
      padding-bottom: 84px;
    }
    @include pc {
      padding-bottom: 20px;
    }
    ul {
      @include sp {
        display: flex;
        margin: 24px 0 0 0;
      }
    }
    li {
      list-style: none;
      line-height: 1.3;
      @include sp {
        margin-right: 45px;
      }
    }
    a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    .fadein-text label {
      @include sp {
        opacity: 1 !important;
        transform: translateX(0) !important;
      }
    }
  }

  &__hover {
    @include sp {
      display: none !important
    }
    display: none;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 9000;
    background: #333;
    transition: background .2s ease-out;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
      .works__hover__inner {
        max-width: calc( 100% - 30px ) !important;
      }
      .works__hover__inner__img {
        width: 415px;
        transform: translateX(0) !important;
      }
    }
    &__inner {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      height: 100%;
      position: relative;
    }
    &__inner__img {
      position: relative;
      margin: auto;
      width: 75%;
      margin-right: calc( 12.5% + 0 );
      transform: translateX(-15px);
      height: calc( 100vh - 112px );
      max-height: calc( 100vh - 112px );
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__inner__img--before,
    &__inner__img--after {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: calc( 100vh - 112px );
    }
    img {
      transition: transform .3s ease-out;
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: calc( 100vh - 112px );
    }
    &.indexListColor--0 {
        background: #ffff00;
    }
    &.indexListColor--1 {
        background: #ffff00;
    }
    &.indexListColor--2 {
        background: #ffff00;
    }
    &.indexListColor--3 {
        background: #ffff00;
    }
    &.indexListColor--4 {
        background: #ffff00;
    }
    &.indexListColor--5 {
        background: #ffff00;
    }
    &.indexListColor--6 {
        background: #ffff00;
    }
    &.indexListColor--7 {
        background: #ffff00;
    }
    &.indexListColor--8 {
        background: #ffff00;
    }
  }

  &__scroll {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 9000;
    background: $black-bg url(img/works_scroll.svg) no-repeat center center;
    background-size: cover;
    @include sp {
      display: none !important
    }
    @include tab {
      display: none;
      &.max-width {
        width: calc( 100% - #{$min-width} );
      }
      &.min-width {
        width: #{$min-width};
      }
    }
    @include pc {
      display: none;
      &.max-width {
        width: calc( 100% - #{$min-width} );
      }
      &.min-width {
        width: #{$min-width};
      }
    }
  }

  &__loading {
    display: none;
    //pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 7000;
    background: #{$black-bg};
    @include sp {
      width: 100%;
    }
    @include tab {
      width: 50%;
      &.max-width {
        width: calc( 100% - #{$min-width} );
      }
      &.min-width {
        width: #{$min-width};
      }
    }
    @include pc {
      width: 50%;
      &.max-width {
        width: calc( 100% - #{$min-width} );
      }
      &.min-width {
        width: #{$min-width};
      }
    }
    img {
      position: absolute;
      animation: loadingWorks .75s linear infinite;
      @include sp {
        width: 80px;
        top: 50%;
        left: calc( 50% + 15px );
      }
      @include tab {
        width: 100px;
        top: 50%;
        left: calc( 50% + 15px );
      }
      @include pc {
        width: 100px;
        top: 50%;
        left: 50%;
      }
    }
    @keyframes loadingWorks {
      0% {
        transform: translate(-50%, -50%) rotate(0);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }

}

.works__hover__divider {
  border-bottom: 1px solid #fff;
  position: fixed;
  right: 0;
  @include sp {
    top: 40px;
    width: 100%;
    z-index: 5000;
  }
  @include tab {
    top: 50px;
    width: 50%;
    z-index: 9000;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    top: 50px;
    width: 50%;
    z-index: 9000;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
}

.works__more__loading {
  display: none;
  padding: 11px 0;
}

.works__more__loading svg {
  pointer-events: none;
  width: 21px;
  height: 21px;
  display: block;
  margin: auto;
  animation: more_loading 1s linear;
  animation-iteration-count: infinite;
  @include sp {
    margin-left: calc( 50% + 20px );
  }
}

@keyframes more_loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.works.min-width .works__right h2 {
  @include tab {
    top: 42px;
  }
  @include pc {
    top: 42px;
  }
}

.sp-mask {
  overflow-y: visible;
}
