@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=97d341f1-1c87-444f-b198-b69d35df990f");
@font-face{
    font-family:"Caslon Bd BT W05 Regular";
    src:url("fonts/a1567429-088a-4ad5-8db4-8482658f0ad7.eot?#iefix");
    src:url("fonts/a1567429-088a-4ad5-8db4-8482658f0ad7.eot?#iefix") format("eot"),url("fonts/f58f0d27-b81f-499a-b1b7-1d03cff94d1f.woff2") format("woff2"),url("fonts/8de76a55-ffe1-4e1b-84d1-6e44455a836c.woff") format("woff"),url("fonts/14541a71-fb43-48d8-8256-27d5fcaf78f3.ttf") format("truetype");
}
@font-face{
    font-family:"Caslon Bold W01 Regular";
    src:url("fonts/0e142d75-1bd4-4e3a-8352-1b54cc8a6959.eot?#iefix");
    src:url("fonts/0e142d75-1bd4-4e3a-8352-1b54cc8a6959.eot?#iefix") format("eot"),url("fonts/01a1c33d-fd23-421a-8be4-8928a4677360.woff2") format("woff2"),url("fonts/296dd293-4b5f-4eaf-8c6b-995f2e0690a2.woff") format("woff"),url("fonts/e70e5470-bae6-4b40-8666-019c08a79cec.ttf") format("truetype");
}
@font-face{
    font-family:"Caslon Bold W03 Rg";
    src:url("fonts/a3f4b1fa-81e0-4e53-b696-20410c92a64b.eot?#iefix");
    src:url("fonts/a3f4b1fa-81e0-4e53-b696-20410c92a64b.eot?#iefix") format("eot"),url("fonts/f4dedef7-7e96-4e84-a50b-351b8f53bf2e.woff2") format("woff2"),url("fonts/fbf7bda6-568a-4a31-89e2-8b611a04c00e.woff") format("woff"),url("fonts/f5fb7a06-c2bc-48d6-ab66-05f69b9acda7.ttf") format("truetype");
}
@font-face{
    font-family:"Helvetica Neue LT W05_55 Roman";
    src:url("fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix");
    src:url("fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("eot"),url("fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),url("fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"),url("fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}
@font-face{
    font-family:"Helvetica Neue LT W05_65 Medium";
    src:url("fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix");
    src:url("fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("eot"),url("fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),url("fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"),url("fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
}
@font-face{
    font-family:"Helvetica Neue LT W05_75 Bold";
    src:url("fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix");
    src:url("fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("eot"),url("fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"),url("fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"),url("fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
}
@font-face{
    font-family:"Helvetica LT W01 Bold";
    src:url("fonts/05f47e74-d17c-45af-86c3-8ab878fd4ec7.eot?#iefix");
    src:url("fonts/05f47e74-d17c-45af-86c3-8ab878fd4ec7.eot?#iefix") format("eot"),url("fonts/7ce0a2f5-eb00-46aa-919c-5b3f3667646c.woff2") format("woff2"),url("fonts/cad22c74-45b4-4c49-9e6a-0cd3768a7bc7.woff") format("woff"),url("fonts/8ebb9b04-d12d-488e-aa96-0bb6e6777336.ttf") format("truetype");
}
@font-face{
    font-family:"Swiss_721 W01 Extended";
    src:url("fonts/7c78f7cd-458a-4c95-9405-02e8c70a53d0.eot?#iefix");
    src:url("fonts/7c78f7cd-458a-4c95-9405-02e8c70a53d0.eot?#iefix") format("eot"),url("fonts/8b60e990-0094-48cf-93c5-f258ed3ce87a.woff2") format("woff2"),url("fonts/c97d6d8b-c9b8-4792-9c5d-2cd017dc9d06.woff") format("woff"),url("fonts/f8faf30e-6940-4ad6-bd94-7ebef532aae5.ttf") format("truetype");
}
@font-face{
    font-family:"Swiss721W01-BoldExtende";
    src:url("fonts/c3e600db-4b91-49b3-be13-7527a8b96102.eot?#iefix");
    src:url("fonts/c3e600db-4b91-49b3-be13-7527a8b96102.eot?#iefix") format("eot"),url("fonts/ac12f38b-c2b6-40b8-9aff-01b0c4ac57ab.woff2") format("woff2"),url("fonts/f4026334-ad61-402b-b3b6-5e475e8aa4a1.woff") format("woff"),url("fonts/b157919f-ff0a-4a7b-9121-4897c0def087.ttf") format("truetype");
}

.caslon {
  font-family:"Caslon Bold W03 Rg";
}
.swiss721_extend {
  font-family: "Swiss_721 W01 Extended", sans-serif;
}
.swiss721_extend_bold {
  font-family: "Swiss721W01-BoldExtende", sans-serif;
}
.helvetica_bold {
  font-family: "Helvetica LT W01 Bold", sans-serif;
}
.Helvetica_Neue_LT_W05_55_Roman {
  font-family: "Helvetica Neue LT W05_55 Roman", sans-serif;
}
.helvetica_neue_medium {
  font-family: "Helvetica Neue LT W05_65 Medium", sans-serif;
}
