// 共通

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  max-width: 100%;
}

*::-webkit-scrollbar{
 display:none;
}

html,
body {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  max-width: 100vw;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  font-family: "a-otf-futo-go-b101-pr6n", sans-serif;
  font-feature-settings: 'palt';
  font-kerning: normal;
  line-height: 1.555;
  position: relative;
  hyphens: auto;
  @include pc {
    @include letterSpacing(18px, 75);
  }
  &.body-is-fixed {
    position: absolute;  /* またはfixed */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}

a:hover {
  text-decoration: none;
}

.no-selection {
  user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -khtml-user-select: none !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  * {
    user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    -khtml-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
  }
}

/*
#bar {
  position: absolute;
  width: 100px;
  height: 100%;
  z-index: 9000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bartext {
  @extend .caslon;
  font-weight: bold;
  font-size: 47px;
  position: absolute;
  width: 60px;
  height: 100%;
  overflow: hidden;
  word-wrap: normal;
  z-index: 9000;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
}
*/

.bar {
  @include sp {
    pointer-events: none !important;
    display: none !important;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9500;
  pointer-events: none;
  textPath {
    //opacity: 0;
    //transition: opacity .2s ease-out;
    &.is-active {
      //opacity: 0;
      //animation: fadeintext 1s ease-out;
      animation-fill-mode: forwards;
    }
  }
  @keyframes fadeintext {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  & .line {
    stroke: red;
    pointer-events: auto;
  }
  &.yellow .line {
    stroke: yellow;
  }
  &.blue .line {
    stroke: blue;
  }
  &.blue .text {
    fill: white;
  }
  &.blue + .sp-text {
    color: white;
  }
  &.hover-news .line {
    stroke: blue;
  }
  &.hover-news .text {
    fill: white;
  }
  &.hover-works .line {
    //stroke: #00ffff !important;
    stroke: yellow;
  }
  &.hover-works .text {
    fill: black;
  }
  &.move .line {
    stroke: red !important;
  }
  &.move .text {
    fill: #000 !important;
  }
  &.indexListColor--0:hover a,
  &.indexListColor--0:active a {
    //color: #FF0000;
    color: #FF0000;
  }
  &.indexListColor--0:hover:before,
  &.indexListColor--0:active:before,
  &.indexListColor--0:hover:after,
  &.indexListColor--0:active:after {
    //background: #FF0000;
    background: #ffff00;
  }
  &.indexListColor--0 .line {
    //stroke: #00FFFF !important;
    stroke: yellow !important;
  }
  &.indexListColor--0 .text {
    fill: #000 !important;
  }
  &.indexListColor--1 .line {
    //stroke: #00FFFF !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--1 .text {
    fill: #000 !important;
  }
  &.indexListColor--2 .line {
    //stroke: #007475 !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--2 .text {
    fill: #000 !important;
  }
  &.indexListColor--3 .line {
    //stroke: #FFFF00 !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--3 .text {
    fill: #000 !important;
  }
  &.indexListColor--4 .line {
    //stroke: #FF9AFF !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--4 .text {
    fill: #000 !important;
  }
  &.indexListColor--5 .line {
    //stroke: #B3B3B3 !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--5 .text {
    fill: #000 !important;
  }
  &.indexListColor--6 .line {
    //stroke: #C7B299 !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--6 .text {
    fill: #000 !important;
  }
  &.indexListColor--7 .line {
    //stroke: #FF5A9F !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--7 .text {
    fill: #000 !important;
  }
  &.indexListColor--8 .line {
    //stroke: #66DFBF !important;
    stroke: #ffff00 !important;
  }
  &.indexListColor--8 .text {
    fill: #000 !important;
  }

}

.sp-bar-nav {
  @extend .helvetica_bold;
  @include sp {
    position: fixed;
    top: 0;
    width: 40px;
    height: 100%;
    z-index: 9999;
    font-size: 21px;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
    transition: left .5s cubic-bezier(0.19, 1, 0.22, 1);
    &.is-left {
      //animation: toleftnav 2s #{$easeOutExpo} normal forwards running;
      left: 0;
    }
    &.is-right {
      //animation: torightnav 2s #{$easeOutExpo} normal forwards running;
      left: calc( 100% - 40px );
    }
  }
}

@keyframes toleftnav {
  0% {
    left: calc( 100% - 40px );
  }
  50% {
    left: calc( 50% - 20px );
  }
  100% {
    left: 0;
  }
}

@keyframes torightnav {
  0% {
    left: 0;
  }
  50% {
    left: calc( 50% - 20px );
  }
  100% {
    left: calc( 100% - 40px );
  }
}

.sp-bar {
  @include sp {
    position: fixed;
    top: 0;
    width: 40px;
    height: 100%;
    background: red;
    z-index: 9000;
    transition: all .5s cubic-bezier(0.19, 1, 0.22, 1), color .2s ease-out;;
    &.is-left {
      //animation: toleft 2s #{$easeOutExpo} normal forwards running;
      left: 0;
    }
    &.is-right {
      //animation: toright 2s #{$easeOutExpo} normal forwards running;
      left: calc( 100% - 40px );
    }
    &.yellow {
      background: yellow;
    }
    &.blue {
      background: blue;
      color: white;
    }
  }
  @include tab {
    display: none !important;
  }
  @include pc {
    display: none !important;
  }
}

@keyframes toleft {
  0% {
    left: calc( 100% - 40px );
    width: 40px;
    pointer-events: none;
  }
  50% {
    width: 100vw;
    pointer-events: none;
  }
  100% {
    left: 0;
    width: 40px;
    pointer-events: inherit;
  }
}

@keyframes toright {
  0% {
    left: 0;
    width: 40px;
    pointer-events: none;
  }
  50% {
    left: 0;
    width: 100vw;
    pointer-events: none;
  }
  100% {
    left: calc( 100% - 40px );
    width: 40px;
    pointer-events: inherit;
  }
}

.sp-bar-text {
  @extend .caslon;
  @include sp {
    position: fixed;
    top: 0;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-size: 32px;
    padding-top: 40px;
    line-height: 1;
    //transform: translateX(4px);
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }
  @include tab {
    display: none !important;
  }
  @include pc {
    display: none !important;
  }
}

.is-left .sp-bar-text {
  //animation: tolefttext 2s #{$easeOutExpo} normal forwards running;
  transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
  left: 4px;
}

.is-right .sp-bar-text {
  //animation: torighttext 2s #{$easeOutExpo} normal forwards running;
  transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
  left: calc( 100% - 36px );
}

@keyframes tolefttext {
  0% {
    left: calc( 100% - 36px );
  }
  50% {
    left: calc( 50% - 18px );
  }
  100% {
    left: 4px;
  }
}

@keyframes torighttext {
  0% {
    left: 4px;
  }
  50% {
    left: calc( 50% - 18px );
  }
  100% {
    left: calc( 100% - 36px );
  }
}

.text {
  @extend .caslon;
  font-size: 40px;
  fill: black;
  width: 100%;
  height: 100%;
  @include letterSpacing(40px, 25);
  transition: all .2s ease-out;
  cursor: grab;
  @include tab {
    transform: translate(-4px, 0px);
  }
  @include pc {
    transform: translate(-4px, 0px);
  }
}

.line {
  //transition: left .2s ease-out, right .2s ease-out;
  transition: transform .2s ease-out;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: grab;
  @include tab {
    stroke-width: 60px;
  }
  @include pc {
    stroke-width: 60px;
  }
}

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

.pc_only {
  @include pc {
    display: inherit;
  }
  @include sp {
    display: none !important;
  }
}

.sp_only {
  @include pc {
    display: none !important;
  }
  @include sp {
    display: inherit;
  }
}


.main {
  display: flex;
  @include sp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //overflow: hidden;
    //overflow-y: scroll;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
  }
  @include tab {
    height: 100%;
    width: 100%;
    //overflow: scroll;
  }
  @include pc {
    height: 100%;
    width: 100%;
    //overflow: scroll;
  }
}

.left,
.right {
  @include sp {
    height: 100%;
    overflow-y: scroll;
    width: 0 !important;
    transition: width .5s $easeOutExpo;
  }
  @include tab {
    width: 50%;
    overflow-y: scroll;
    transition: width 2s $easeOutExpo;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    transition: width .1s linear;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  .more-button {
    @extend .helvetica_neue_medium;
    background: none;
    cursor: pointer;
    position: relative;
    transition: all .1s ease-out;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 4px 0;
    font-size: 19px;
    line-height: 40px;
    letter-spacing: 1px;
    &--left {
      color: #000;
      margin-top: -5px;
      transform: translateX(-30px);
    }
    &--right {
      color: #fff;
      transform: translateX(30px);
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 5000;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform .3s ease-out;
      margin-top: -2px;
    }
    &.more-button--left:before {
      background: blue;
    }
    &.more-button--left.hover {
      @include sp {
        color: blue;
      }
    }
    &.more-button--left:hover {
      @include tab {
        color: blue;
      }
      @include pc {
        color: blue;
      }
    }
    &.more-button--right:before {
      background: yellow;
    }
    &.more-button--right.hover {
      @include sp {
        color: yellow;
      }
    }
    &.more-button--right:hover {
      @include tab {
        color: yellow;
      }
      @include pc {
        color: yellow;
      }
    }
    &:hover:before {
      @include tab {
        transform: scaleX(1);
      }
      @include pc {
        transform: scaleX(1);
      }
    }
    &.hover:before {
      @include sp {
        transform: scaleX(1);
      }
    }
  }
}

#more-button-left.last,
#more-button-right.last {
  display: none !important;
}

.left {
  & *::selection {
    background: blue;
    color: white;
  }
  & *::-moz-selection {
    background: blue;
    color: white;
  }
  & .news__hover__divider {
    @include sp {
      width: 0 !important;
    }
  }
  &.sp-active {
    @include sp {
      width: 100% !important;

      & .news__hover__divider {
        width: 100% !important;
      }

    }
  }
}

.right {
  background: #{$black-bg};
  & *::selection {
    background: #00FFFF;
  }
  & *::-moz-selection {
    background: #00FFFF;
  }
  & .works__hover__divider {
    @include sp {
      width: 0 !important;
    }
  }
  &.sp-active {
    @include sp {
      width: 100% !important;

      & .works__hover__divider {
        width: 100% !important;
      }

    }
  }
}

/*
$barSize: 48px;

.left {
  &.is_open {
    width: calc( 100% - #{$barSize} );
  }
  &.is_open + .right {
    width: $barSize;
  }
  &.is_close {
    width: $barSize;
  }
  &.is_close + .right {
    width: calc( 100% - #{$barSize} );
  }
}

.left.is_open {
  .index__left {
    width: calc( 100vw - #{$barSize} );
  }
  & + .right .index__right {
    width: $barSize;
  }
  & + .right .index__right__list {
    opacity: 0;
    pointer-events: none;
  }
  .news {
    width: calc( 100vw - #{$barSize} );
  }
  & + .right .works {
    width: $barSize;
  }
}

.left.is_close {
  .index__left {
    width: $barSize;
  }
  .index__left__list {
    opacity: 0;
    pointer-events: none;
  }
  & + .right .index__right {
    width: calc( 100vw - #{$barSize} );
  }
  .news {
    width: $barSize;
  }
  & + .right .works {
    width: calc( 100vw - #{$barSize} );
  }
}
*/

.hover-text {
  //@include letterSpacing(20px, -100);
  display: block;
  label {
    opacity: 0;
    pointer-events: none;
    @for $i from 0 through 10 {
      &:nth-child(#{$i}) {
        transform: translateX(-20px);
        transition: all .1s ease-out calc( #{$i} * .025s );
      }
    }
  }
  span:hover + label,
  span:active + label {
    opacity: 1;
    transform: translateX(0);
    pointer-events: inherit;
  }

  span {
    display: inline-block;
    @include letterSpacing(20px, -100);
  }

  label {
    display: inline-block;
    @include letterSpacing(20px, 0);
  }

  span:hover + label,
  span:active + label {
    transform: translateX(-5px);
  }
}

.gutter {
  cursor: col-resize;
  width: 28px !important;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: 10000;
  //background: red;
  margin-left: -14px;
}

.fadein-text {
  * {
    cursor: pointer;
  }
  label {
    opacity: 0;
    transition: all .15s ease-out;
    transform: translateX(-20px);
  }
  &:hover,
  &:active {
    label {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #{$black-bg};
  z-index: 9999;
  #loading__percentage {
    position: fixed;
    top: 0;
    left: 10px;
    color: #fff;
    @extend .swiss721_extend_bold;
    @include sp {
      font-size: 28px;
    }
    @include tab {
      font-size: 39px;
    }
    @include pc {
      font-size: 39px;
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
    transform-origin: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp {
      transform: scale( #{calc( 80px / ( 100% - 20px ) )} );
    }
    @include tab {
      transform: scale( #{calc( 100px / ( 100% - 200px ) )} );
    }
    @include pc {
      transform: scale( #{calc( 100px / ( 100% - 200px ) )} );
    }
  }
  img {
    opacity: 0;
    //position: fixed;
    //top: 50%;
    //left: 50%;
    height: auto;
    //transform: translate(-50%, -50%) rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    backface-visibility: hidden;
    transition: opacity .2s ease-out;
    @include sp {
      width: calc( 100vw - 40px );
      max-width: calc( 100vw - 40px );
      min-width: 80px;
    }
    @include tab {
      width: calc( 100vw - 200px );
      max-width: calc( 100vw - 200px );
      min-width: 100px;
      max-height: calc( 100% - 200px );
    }
    @include pc {
      width: calc( 100vw - 200px );
      max-width: calc( 100vw - 200px );
      min-width: 100px;
      max-height: calc( 100% - 200px );
    }
  }
  &.is-loading img {
    animation: loading .75s linear infinite;
  }
}

@keyframes loading {
  0% {
    //transform: translate(-50%, -50%) rotate(0);
    transform: rotate(0);
  }
  100% {
    //transform: translate(-50%, -50%) rotate(360deg);
    transform: rotate(360deg);
  }
}

.no-letterspacing {
  letter-spacing: 0;
}
