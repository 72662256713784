$grid_color: "rgba(0, 0, 0, .25)";
.grid_pc {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  pointer-events: none;
  *zoom: 1;
  overflow: hidden;

  &:before,
  &:after {
      content: " ";
      display: table;
  }

  &:after {
      clear: both;
  }

  &__row__wrap,
  &__col__wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &__row {
    box-sizing: border-box;
    width: 100%;
    height: 14px;
    border-bottom: 1px solid #{$grid_color};
    &:first-child {
      margin-top: 14px;
      border-top: 1px solid #{$grid_color};
    }
  }

  &__col {
    box-sizing: border-box;
    border-left: 1px solid #{$grid_color};
    border-right: 1px solid #{$grid_color};
    width: calc( 100vw / 8 - 14px );
    height: 100vh;
    margin: 0 7px;
    float: left;
    &:first-child {
      margin-left: 28px;
      width: calc( 100% / 8 - 35px );
    }
    &:nth-child(4) {
      margin-right: 28px;
      width: calc( 100% / 8 - 35px );
    }
    &:nth-child(5) {
      margin-left: 28px;
      width: calc( 100% / 8 - 35px );
    }
    &:last-child {
      margin-right: 28px;
      width: calc( 100% / 8 - 35px );
    }
  }
}

.grid_sp {
  width: calc( 100vw - 30px );
  height: calc( 100vh - 30px );
  position: fixed;
  top: 0;
  z-index: 99999;
  pointer-events: none;
  *zoom: 1;
  overflow: hidden;
  margin: 15px;

  &:before,
  &:after {
      content: " ";
      display: table;
  }

  &:after {
      clear: both;
  }

  &__col__wrap {
    width: 100%;
    height: calc( 100% - 2px );
    border-top: 1px solid #{$grid_color};
    border-bottom: 1px solid #{$grid_color};
  }

  &__col {
    box-sizing: border-box;
    border-left: 1px solid #{$grid_color};
    border-right: 1px solid #{$grid_color};
    width: calc( 100% / 5 );
    height: 100%;
    float: left;
  }

  &__row__wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 15px;
    left: 15px;
  }

  &__row {
    width: 100%;
    height: calc( 50vh - 30px - 1px );
    border-bottom: 1px solid #{$grid_color};
  }

}
