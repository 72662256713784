.about {
  box-sizing: border-box;
  background: #{$white-bg};
  padding-bottom: 56px;
  padding-right: 44px;
  position: fixed;
  left: 0;
  z-index: 5000;
  height: 100%;
  overflow-y: scroll;
  //-webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  transform: translateX(-100%);
  transition: all .75s cubic-bezier(0.19, 1, 0.22, 1);
  @include sp {
    width: 100%;
    padding-left: 14px;
    margin-top: 1px;
    top: 40px;
  }
  @include tab {
    width: 50vw;
    top: 50px;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    width: 50vw;
    top: 50px;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  &.is_active {
    transform: translateX(0);
  }
  a {
    color: #000;
    &:hover {
      color: #00f;
    }
  }
  h2 {
    letter-spacing: calc( 20px * 0.001 * 100);
    @include sp {
      padding-top: 18px;
      margin-bottom: 168px;
      padding-left: 14px;
      font-size: 18px;
      line-height: 21px;
    }
    @include tab {
      padding-top: 18px;
      margin-bottom: 168px;
      padding-left: 28px;
      font-size: 18px;
      line-height: 21px;
    }
    @include pc {
      padding-top: 24px;
      margin-bottom: 172px;
      padding-left: calc( 100% / 4 + 18px );
      font-size: 22px;
      line-height: 25px;
    }
    span {
      display: block;
      &.ja {
        margin-top: -8px;
        transform: scaleY(.85);
        @include sp {
          font-size: 19px;
          line-height: 26px;
        }
        @include tab {
          font-size: 20px;
          line-height: 21px;
        }
        @include pc {
          font-size: 20px;
          line-height: 21px;
        }
      }
      &.en {
        @extend .helvetica_neue_medium;
        @include sp {
          font-size: 16px;
          line-height: 22px;
        }
        @include tab {
          font-size: 18px;
          line-height: 28px;
        }
        @include pc {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
  &__row {
    @include tab {
      padding-left: 14px;
      margin-bottom: 40px;
    }
    @include pc {
      display: flex;
    }
  }
  &__row h3 {
    padding-left: 14px;
    @include sp {
      font-size: 21px;
      line-height: 32px;
    }
    @include pc {
      width: calc( 100% / 4 );
      margin-top: -3px;
    }
  }
  &__col {
    margin-top: -10px;
    @include sp {
      width: 100%;
      padding: 0 28px 0 14px;
      margin-bottom: 24px;
    }
    @include tab {
      width: 100%;
      padding: 0 28px 0 14px;
      margin-bottom: 20px;
    }
    @include pc {
      width: calc( 100% / 4 * 3 );
      padding: 0 14px 0 18px;
      margin-bottom: 20px;
    }
    &.profile {
      @include pc {
        margin-bottom: 40px;
      }
      .ja {
        transform: scaleY(.85);
        line-height: calc( 28px *  1.2 );
        margin-top: -10px;
        margin-bottom: 14px;
        @include sp {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: -6px;
          @include letterSpacing(16px, 75);
        }
        @include pc {
          @include letterSpacing(18px, 75);
        }
      }
      .en {
        @extend .helvetica_neue_medium;
        line-height: 28px;
        hyphens: auto;
        @include sp {
          font-size: 15px;
          line-height: 22px;
          @include letterSpacing(15px, 50);
        }
        @include pc {
          @include letterSpacing(18px, 50);
        }
      }
    }
    &.award {
      @include tab {
        padding-top: 6px;
      }
      @include pc {
        padding-top: 6px;
        margin-bottom: 40px;
      }
      .ja {
        transform: scaleY(0.85);
        line-height: calc( 21px * 1.2);
        font-size: 13px;
        @include tab {
          margin-top: -10px;
        }
        @include pc {
          margin-top: -10px;
          @include letterSpacing(13px, 75);
        }
      }
      .en {
        @extend .Helvetica_Neue_LT_W05_55_Roman;
        font-size: 13px;
        line-height: 21px;
        hyphens: auto;
        @include letterSpacing(13px, 75);
      }
    }
  }

  &__award__list {
    transition: all .2s ease-out;
    &:not(.not-active):hover,
    &:not(.not-active):active {
      color: #0000ff;
      cursor: pointer;
    }
  }

  &__scroll {
    padding-left: 14px;
    @include sp {
      margin-bottom: 36px;
    }
    @include tab {
      margin-bottom: 56px;
    }
    @include pc {
      margin-bottom: 56px;
    }
    svg {
      width: 14px;
      margin-top: 122px;
    }
    .cls-1 {
      width: 14px;
      height: 14px;
      fill: none;
      stroke: #231815;
      stroke-miterlimit: 10;
    }
  }

}
