.news {
  position: fixed;
  left: 0;
  z-index: 1000;
  overflow-y: scroll;
  overscroll-behavior: none;
  background: #{$white-bg};
  transform: translateX(-100%);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), width .1s linear;
  padding-left: 14px;
  padding-right: 28px;
  @include sp {
    top: 40px;
    width: calc( 100% - 30px );
    height: calc( 100% - 40px );
  }
  @include tab {
    top: 50px;
    width: 50%;
    height: calc( 100% - 50px );
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    top: 50px;
    width: 50%;
    height: calc( 100% - 50px );
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
      .news__date__sp {
        display: block !important;
      }
      .news__left {
        display: none;
      }
      .news__right {
        width: 100%;

        .category, .year {
          @include pc {
            display: inline !important;
          }
        }

        h2 {
          margin-bottom: 0;
        }

        &__content {
          @include tab {
            margin-top: 25px;
          }
          @include pc {
            margin-top: 25px;
          }
        }

      }
    }
  }
  &.is_active {
    //overflow: scroll !important;
    transform: translateX(0);
  }

  &__row {
    display: flex;
  }

  &__left {
    //@extend .swiss721_extend;
    transform: scaleY(.85);
    transform-origin: top;
    padding-top: 19px;
    line-height: 22px;
    width: calc( 25% - 10px );
    @include sp {
      display: none;
    }
    @include tab {
      display: none;
    }
  }

  &__left__arrow {
    width: calc( 25% - 10px );
    padding-top: 24px;
    @include tab {
      margin-left: 16px;
    }
    @include pc {
      margin-left: 16px;
    }
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      .cls-1{fill:none;stroke:#231815;stroke-miterlimit:10;}
    }
    svg:hover,
    svg:active {
      .cls-1 {
        stroke: #0000FF;
      }
    }
  }

  &__right {
    position: relative;
    @include sp {
      width: 100%;
      padding: 0px 7px 56px 14px;
      margin-bottom: 84px;
    }
    @include tab {
      width: 100%;
      padding: 10px 28px 72px 14px;
    }
    @include pc {
      width: calc( 75% + 10px );
      padding: 10px 28px 72px 14px;
    }
  }

  &__right h2 {
    font-size: 20px;
    transform: scaleY(.85);
    position: absolute;
    @include sp {
      margin-bottom: 0;
      top: 28px;
    }
    @include tab {
      margin-bottom: 0;
      top: 38px;
    }
    @include pc {
      margin-bottom: 191px;
      @include letterSpacing(20px, 100);
    }
  }

  &__date__sp {
    transform: scaleY(0.85);
    letter-spacing: 1px;
    @include sp {
      margin-bottom: 214px;
      line-height: 1;
      margin-top: 7px;
    }
    @include tab {
      //margin-bottom: 214px;
      margin-top: 2px;
    }
    @include pc {
      display: none;
    }
  }

  &__right__content {
    margin-bottom: calc( -28px * 3 );
    @include sp {
      padding-top: 206px;
    }
    @include tab {
      padding-top: 206px;
      padding-bottom: 124px;
    }
    @include pc {
      //padding-top: 259px;
      padding-top: 218px;
      padding-bottom: 124px;
    }
    li {
      list-style-position: inside;
    }
    iframe {
      overflow: hidden;
      width: 100%;
    }
    p {
      transform: scaleY(.85);
      transform-origin: top;
    }
  }

  &__right__content p {
    line-height: calc( 28px *  1.2 );
    margin-top: 14px;
    @include sp {
      font-size: 16px;
      line-height: 28px;
    }
    a {
      color: #000;
      text-decoration: underline;
    }
  }

  &__right__social {
    @extend .helvetica_neue_medium;
    width: calc( 75% + 10px );
    font-size: 20px;
    @include sp {
      padding-bottom: 36px;
    }
    @include tab {
      padding-left: 3px;
      margin-bottom: 28px;
    }
    @include pc {
      padding-left: 3px;
      margin-bottom: 24px;
    }
    ul {
      @include sp {
        display: flex;
        margin: 24px 0 0 0;
      }
    }
    li {
      list-style: none;
      line-height: 1.3;
      @include sp {
        margin-right: 45px;
      }
    }
    a {
      color: #000;
      transition: all .1s ease-out;
      &:hover,
      &:active {
        color: #0000FF;
        text-decoration: none;
      }
    }
    .fadein-text label {
      @include sp {
        opacity: 1 !important;
        transform: translateX(0) !important;
      }
    }
  }

  &__right__content img {
    width: 100%;
    height: auto;
    margin: 16px 0;
  }

  &__right__content a {
    color: #0000FF;
  }

  .wp-caption {
    width: auto !important;
  }

  .wp-caption-text {
    @include pc {
      margin-top: 5px;
      margin-bottom: 21px;
    }
  }

  &__scroll {
    @include sp {
      display: none !important;
    }
    @include tab {
      display: none;
      &.max-width {
        width: calc( 100% - #{$min-width} );
      }
      &.min-width {
        width: #{$min-width};
      }
    }
    @include pc {
      display: none;
      &.max-width {
        width: calc( 100% - #{$min-width} );
      }
      &.min-width {
        width: #{$min-width};
      }
    }
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
    z-index: 6000;
    background: $white-bg;
    &__inner {
      width: 100%;
      min-height: 100%;
      position: relative;
    }
    img {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 84.6%;
      max-height: 64.7%;
      width: auto;
      height: auto;
      &.is_active {
        @include tab {
          display: block;
        }
        @include pc {
          display: block;
        }
      }
    }
  }

}

.news__hover__divider {
  border-bottom: 1px solid #000;
  position: fixed;
  left: 0;
  @include sp {
    top: 40px;
    width: 100%;
    z-index: 5000;
  }
  @include tab {
    top: 50px;
    width: 50%;
    z-index: 9000;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    top: 50px;
    width: 50%;
    z-index: 9000;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
}

.works__more__loading {
  display: none;
  padding: 11px 0;
}

.works__more__loading svg {
  pointer-events: none;
  width: 21px;
  height: 21px;
  display: block;
  margin: auto;
  animation: more_loading 1s linear;
  animation-iteration-count: infinite;
  @include sp {
    margin-left: calc( 50% - 40px);
  }
}

@keyframes more_loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.news__iframe__wrap {
  position: relative;
  width: 100%;
  &:before {
    content:"";
    display: block;
    padding-top: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.news.min-width .news__right h2 {
  @include tab {
    margin-bottom: 0;
    top: 28px;
    padding-right: 28px;
  }
  @include pc {
    margin-bottom: 0;
    top: 28px;
    padding-right: 28px;
  }
}
