.header {
  width: 100%;

  @include sp {
    height: 40px;
  }
  @include tab {
    height: 50px;
  }
  @include pc {
    height: 50px;
  }

  .fadein-text {
    @include sp {
      padding: 10px;
    }
    @include tab {
      padding: 14px;
    }
    @include pc {
      padding: 14px;
    }
    &:hover,
    &:active {
      label {
        @include sp {
          opacity: 0 !important;
          transform: translateX(-20px) !important;
        }
        @include pc {
          transform: translateX(0px);
        }
      }
    }
  }

  .fadein-text:hover + &__left__menu,
  .fadein-text:active + &__left__menu,
  .fadein-text:hover + &__right__menu,
  .fadein-text:active + &__right__menu {
    @include sp {
      opacity: 1 !important;
    }
    @include tab {
      opacity: 0;
    }
    @include pc {
      opacity: 0;
    }
  }

  .fadein-text.hover + &__left__menu,
  .fadein-text.hover + &__right__menu {
    @include sp {
      opacity: 1 !important;
    }
  }

  &__left, &__right {
    //transform: translate3d(0, 0, 0);
    //-webkit-backface-visibility:hidden;
    //backface-visibility:hidden;
    position: fixed;
    top: 0;
    vertical-align: top;
    line-height: 1;
    width: 100%;
    z-index: 5500;
    @include sp {
      height: 40px;
    }
    @include tab {
      height: 50px;
    }
    @include pc {
      height: 50px;
    }
    h1 {
      @extend .helvetica_bold;
      position: absolute;
      //top: 14px;
      top: 0;
      cursor: pointer;
      @include sp {
        font-size: 21px;
      }
      @include tab {
        font-size: 24.75px;
      }
      @include pc {
        font-size: 24.75px;
      }
    }
    h1:hover + .header__left__menu,
    h1:active + .header__left__menu,
    h1:hover + .header__right__menu,
    h1:active + .header__right__menu {
      @include sp {
        opacity: 0;
      }
    }
    h1.hover + .header__left__menu,
    h1.hover + .header__right__menu {
      @include sp {
        opacity: 0;
      }
    }
    &__menu {
      display: flex;
      @include sp {
        padding-left: 36px;
      }
      @include tab {
        position: absolute;
        left: calc( 100% / 16 + 7px );
        transform: translateX(-14px);
      }
      @include pc {
        position: absolute;
        left: calc( 100% / 16 + 7px );
        transform: translateX(-14px);
      }
    }
    &__menu li {
      @extend .helvetica_neue_medium;
      list-style: none;
      //margin-right: 28px;
      cursor: pointer;
      position: relative;
      @include sp {
        padding: 12px 7px;
        font-size: 15px;
      }
      @include tab {
        padding: 14px 14px;
        font-size: 21px;
        @include letterSpacing(21px, 50);
      }
      @include pc {
        padding: 14px 14px;
        font-size: 21px;
        @include letterSpacing(21px, 50);
      }
    }
    &__menu li,
    &__menu li a {
      transition: all .2s ease-out;
    }
    &__menu li:hover,
    &__menu li:active {
      @include tab {
        animation: headerListRotate .325s linear infinite;
      }
      @include pc {
        animation: headerListRotate .325s linear infinite;
      }
    }
    &__menu li.hover {
      animation: headerListRotate .325s linear infinite;
    }
    &__menu li.is_active {
      transform: rotate(-45deg);
    }
    &__menu span {
      @include pc {
        display: inline !important;
      }
    }
  }

  @keyframes headerListRotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  // left

  &__left {
    background: #{$white-bg};
    color: #000;
  }

  &__left__menu li.is_active a {
    color: #00f;
  }

  &__left__menu li {
    color: #000;
    display: block;
    cursor: pointer;
    &:hover,
    &:active {
      @include sp {
        text-decoration: none;
        color: #0000FF;
      }
      @include tab {
        text-decoration: none;
        color: #0000FF;
      }
      @include pc {
        text-decoration: none;
        color: #0000FF;
      }
    }
    &.hover {
      @include sp {
        text-decoration: none;
        color: #0000FF;
      }
    }
  }

  &__left__menu li a:hover,
  &__left__menu li a:active,
  &__left__menu li.is_active,
  &__left__menu li.is_active a {
    @include tab {
      color: #0000FF;
    }
    @include pc {
      color: #0000FF;
    }
  }

  &__left__menu li.is_active,
  &__left__menu li.is_active a {
    @include sp {
      color: #0000FF;
    }
  }

  &__left__menu li.hover a,
  &__left__menu li.hover.is_active,
  &__left__menu li.hover.is_active a {
    @include sp {
      color: #0000FF;
    }
  }

  &__left__close {
    position: absolute;
    background: none;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    transform: translateX(100%);
    transition: opacity .2s ease-out, transform .2s ease-out;
    @include sp {
      top: 10px;
      right: 56px;
    }
    @include tab {
      top: 14px;
      right: calc( 44px +  50% );
    }
    @include pc {
      top: 16px;
      right: calc( 44px +  50% );
    }
    &.is_active {
      @include sp {
        opacity: 1;
        transform: translateX(0);
        pointer-events: inherit;
      }
      @include tab {
        opacity: 1;
        transform: translateX(0);
        pointer-events: inherit;
      }
      @include pc {
        opacity: 1;
        transform: translateX(0);
        pointer-events: inherit;
      }
    }
    &.is_remove {
      @include sp {
        display: none;
      }
      @include tab {
        opacity: 0;
        transform: translateX(100%);
        pointer-events: none;
      }
      @include pc {
        opacity: 0;
        transform: translateX(100%);
        pointer-events: none;
      }
    }
    svg {
      @include sp {
        height: 14px;
      }
      @include tab {
        width: 25.447px;
        height: 21.916px;
      }
      @include pc {
        width: 25.447px;
        height: 21.916px;
      }
      polygon {
        transition: all .2s ease-out;
      }
      &:hover,
      &:active {
        polygon {
          @include tab {
            fill: #0000FF;
          }
          @include pc {
            fill: #0000FF;
          }
        }
      }
      &.hover {
        polygon {
          @include sp {
            fill: #0000FF;
          }
        }
      }
    }
  }

  // right

  &__right {
    background: #{$black-bg};
    color: #fff;
    padding-left: 30px;
    @include sp {
      padding-left: 40px;
    }
    //border-bottom: 1px solid #fff;
    /*
    &:after {
      content: '';
      position: absolute;
      top: 50px;
      left: 0;
      border-bottom: 3px solid red;
      width: 100%;
      margin-top: -1px;
    }
    */
  }

  &__right__menu {
    @include sp {
      left: 88px;
    }
    @include tab {
      left: calc( 100% / 16 + 28px);
    }
    @include pc {
      transform: translateX(0);
    }
  }

  &__right__menu li {
    color: #fff;
    display: block;
    &:hover,
    &:active {
      @include sp {
        text-decoration: none;
        //color: #00FFFF;
        color: #ffff00;
      }
      @include tab {
        text-decoration: none;
        //color: #00FFFF;
        color: #ffff00;
      }
      @include pc {
        text-decoration: none;
        //color: #00FFFF;
        color: #ffff00;
      }
    }
    &.hover {
      @include sp {
        text-decoration: none;
        //color: #00FFFF;
        color: #ffff00;
      }
    }
  }

  &__right__menu li a:hover,
  &__right__menu li a:active,
  &__right__menu li.is_active {
    @include tab {
      //color: #00FFFF;
      color: #ffff00;
    }
    @include pc {
      //color: #00FFFF;
      color: #ffff00;
    }
  }

  &__right__menu li.is_active {
    @include sp {
      color: #ffff00;
    }
  }

  &__right__menu li.hover a,
  &__right__menu li.hover a,
  &__right__menu li.hover.is_active {
    @include sp {
      //color: #00FFFF;
      color: #ffff00;
    }
  }

  &__right__button {
    position: absolute;
    background: none;
    z-index: 100;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    outline: 0;
    cursor: pointer;
    @include sp {
      top: 10px;
      right: 14px;
    }
    @include tab {
      top: 13px;
      right: calc( 14px + 50% );
    }
    @include pc {
      top: 13px;
      right: calc( 14px + 50% );
    }
    &.is_active {
      opacity: 1;
      transform: translateX(0);
      pointer-events: inherit;
    }
    &.is_remove {
      opacity: 0;
      transform: translateX(100%);
      pointer-events: none;
    }
    svg {
      @include sp {
        width: 15px;
        height: 15px;
      }
      @include tab {
        width: 21px;
        height: 21px;
      }
      @include pc {
        width: 21px;
        height: 21px;
      }
      fill: #fff;
      .cls-1 {
        fill: #fff;
      }
      .cls-1, .cls-2 {
        transition: all .2s ease-out;
      }
      &:hover,
      &:active {
        @include tab {
          .cls-1 {
            //fill: #00FFFF;
            fill: #ffff00;
          }
          .cls-2 {
            //stroke: #00FFFF;
            stroke: #ffff00;
          }
        }
        @include pc {
          .cls-1 {
            //fill: #00FFFF;
            fill: #ffff00;
          }
          .cls-2 {
            //stroke: #00FFFF;
            stroke: #ffff00;
          }
        }
      }
      &.hover {
        @include sp {
          .cls-1 {
            //fill: #00FFFF;
            fill: #ffff00;
          }
          .cls-2 {
            //stroke: #00FFFF;
            stroke: #ffff00;
          }
        }
      }
    }
  }

  &__right__close {
    display: none;
    position: absolute;
    background: none;
    z-index: 100;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    outline: 0;
    cursor: pointer;
    @include sp {
      top: 10px;
      right: 14px;
    }
    @include tab {
      top: 14px;
      right: calc( 14px + 50% );
    }
    @include pc {
      top: 14px;
      right: calc( 14px + 50% );
    }
    &.is_active {
      display: block;
      opacity: 1;
      transform: translateX(0);
      pointer-events: inherit;
    }
    &.is_remove {
      @include sp {
        display: none;
      }
      @include tab {
        opacity: 0;
        transform: translateX(100%);
        pointer-events: none;
      }
      @include pc {
        opacity: 0;
        transform: translateX(100%);
        pointer-events: none;
      }
    }
    svg {
      width: 25.447px;
      height: 21.916px;
      fill: #fff;
      @include sp {
        height: 14px;
      }
      &:hover,
      &:active {
        polygon {
          @include tab {
            //fill: #00FFFF;
            fill: #ffff00;
          }
          @include pc {
            //fill: #00FFFF;
            fill: #ffff00;
          }
        }
      }
      &.hover {
        polygon {
          @include sp {
            //fill: #00FFFF;
            fill: #ffff00;
          }
        }
      }
    }
  }

}

.min-size-none {
  @include sp {
    display: none !important;
  }
  @include tab {
    display: none !important;
  }
}

.left.min-width .header__left__close.is_active {
  right: calc( 100% - #{$min-width} + 42px);
}

.left.min-width .header__left__menu,
.right.min-width .header__right__menu {
  left: 70px;
}

.right.min-width .header__right__close,
.right.min-width .header__right__button {
  right: calc( 100% - #{$min-width} + 14px);
}

//calc( 100% - 430px + 14px)

.left.max-width .header__left__close.is_active {
  //right: calc( 100% - ( 50% + 247px ) );
  right: 0;
  left: calc( 100% - 470px - 80px );
}

.right.max-width .header__right__button,
.right.max-width .header__right__close {
  @include tab {
    right: calc( 100% - ( 100% - #{$min-width} ) + 20px );
  }
  @include pc {
    right: calc( 100% - ( 100% - #{$min-width} ) + 20px );
  }
}
