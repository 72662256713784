.index {
  /*
  display: flex;
  max-height: calc( 100vh - 50px );
  overflow: hidden;
  */

  &__left__arrow,
  &__right__arrow {
    width: calc( 25% - 10px );
    margin: auto;
    @include sp {
      padding: 22px 0;
    }
    @include tab {
      padding: 30px 0;
    }
    @include pc {
      padding: 24px 0;
    }
    cursor: pointer;
    svg {
      display: block;
      margin: auto;
      width: 14px;
      height: 14px;
      .cls-1{
        fill:none;
        stroke-miterlimit:10;
      }
    }
  }

  &__left__arrow {
    svg {
      @include pc {
        transform: translateX(-30px);
      }
      .cls-1{
        stroke:#000;
      }
    }
    svg:hover ,
    svg:active {
      .cls-1 {
        stroke: #00f;
      }
    }
  }

  &__right__arrow {
    @include pc {
      transform: translateX(30px);
    }
    svg {
      .cls-1{
        stroke:#fff;
      }
    }
    svg:hover ,
    svg:active {
      .cls-1 {
        stroke: #ff0;
      }
    }
  }

  &__left,
  &__right {
    overflow-x: hidden;
    @include sp {
      //overflow: scroll;
      //-webkit-overflow-scrolling: touch;
      overscroll-behavior: none;
      position: fixed;
      max-width: 100%;
      max-height: calc( 100% - 40px );
    }
    @include tab {
      //overflow: hidden;
    }
    @include pc {
      overflow: hidden;
    }
  }

  &__left__list,
  &__right__list {
    margin-bottom: 0;
    @include tab {
      //overflow-y: scroll;
    }
    @include pc {
      //overflow-y: scroll;
    }
  }

  &__left__date,
  &__right__category,
  &__right__year {
    @extend .swiss721_extend;
    margin-bottom: 0;
    @include sp {
      font-size: 13px;
      line-height: 14px;
    }
    @include tab {
      font-size: 17px;
      line-height: 17px;
    }
    @include pc {
      font-size: 17px;
      line-height: 17px;
    }
  }

  &__left__date {
    @include tab {

    }
    @include pc {
      padding-top: 3px;
    }
  }

  &__left,
  &__right {
    //-webkit-overflow-scrolling: touch;
  }

  // left

  &__left {
    background: #{$white-bg};
    color: #000;
    //padding-top: 2.5px;
  }

  &__left__date {
    @include sp {
      font-size: 17px !important;
      line-height: 1 !important;
    }
    @include pc {
      //padding: 7px 0;
    }
  }

  &__left__list {
    //padding-bottom: 5px;
    padding-top: 2.5px;
  }

  &__left__list li {
    position: relative;
    border-bottom: 1px solid #000;
    list-style: none;
    //transform: translateX(-100%);
    //transition-property: transform;
    //transition-duration: .75s;
    //transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 500;
    &.is_active {
      transform: translateX(0);
    }
    &:before, &:after {
    //&:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 5000;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform .3s ease-out;
      background: blue;
      margin-top: -2px;
    }

    &:hover:before,
    &:hover:after,
    &:active:before,
    &:active:after {
      @include pc {
        transform: scaleX(1);
      }
    }

    &.active:before,
    &.active:after {
     @include sp {
       transform: scaleX(1);
     }
     @include tab {
       transform: scaleX(1);
     }
    }
    &:before {
      top: -1px;
    }
    &:after {
      top: 100%;
    }
    @include sp {
      padding: 8px 0;
    }
    @include tab {
      padding: 8px 0 4px;
      padding-right: 44px;
    }
    @include pc {
      padding: 8px 0 10px;
      padding-right: 44px;
    }
  }

  &__left__list li a {
    color: #000;
    transition: all .1s ease-out;
    @include pc {
      display: flex;
    }
  }

  &__left__list li:hover a,
  &__left__list li:active a,
  &__left__list li.active a {
    @include sp {
      color: #0000FF;
      text-decoration: none;
    }
    @include tab {
      color: #0000FF;
      text-decoration: none;
    }
    @include pc {
      color: #0000FF;
      text-decoration: none;
    }
  }

  &__left__list li.hover a {
    @include sp {
      color: #0000FF;
      text-decoration: none;
    }
  }

  &__left__date {
    @extend .swiss721_extend;
    letter-spacing: 1px;
    padding-left: 14px;
    @include sp {
      //width: 118px;
    }
    @include tab {
      font-size: 18px;
      //width: calc( 100% / 4 );
    }
    @include pc {
      font-size: 18px;
      width: calc( 100% / 4 );
    }
  }

  &__left h3 {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 33px;
    transform: scaleY(.85);
    @include sp {
      margin-bottom: -8px;
      font-size: 33px;
      line-height: 36px;
      margin-top: -3px;
      letter-spacing: 1px;
      width: 100%;
      padding: 6px 0 6px 14px;
      padding-right: 56px;
      padding-left: 14px;
    }
    @include tab {
      width: 100%;
      padding-left: 14px;
      margin-top: 4px;
      padding: 6px 0 6px 14px;
      @include letterSpacing(30px, 100);
    }
    @include pc {
      width: calc( 100% - ( 100% / 3 ) + 56px );
      padding-left: calc( ( 100% / 4 / 4 ) );
      @include letterSpacing(30px, 100);
    }
  }

  // right

  &__right {
    background: #{$black-bg};
    color: #fff;
    //padding-top: 2.5px;
    @include sp {
      width: 100%;
    }
  }
  &__right__list[data-mode="thumb"] {
    width: 100%;
    margin: 0 auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp {
      padding: 12px 0px 28px 40px;
    }
    @include tab {
      padding: 24px 14px 28px 44px;
    }
    @include pc {
      padding: 24px 14px 28px 44px;
    }
  }
  &__right__list[data-mode="thumb"] li {
    list-style: none;
    @include sp {
      width: calc( 100% - 28px);
      margin: 0 24px 12px;
    }
    @include tab {
      width: calc( 100% - 28px);
      margin: 0 14px 16px;
    }
    @include pc {
      width: calc( 50% - 28px);
      margin: 0 14px 16px;
    }
  }
  &__right__list[data-mode="thumb"] li h3,
  &__right__list[data-mode="thumb"] li .index__right__category,
  &__right__list[data-mode="thumb"] li .index__right__year {
    //@extend .helvetica_neue_medium;
    @extend .Helvetica_Neue_LT_W05_55_Roman;
    margin-bottom: 0;
    @include sp {
      font-size: 12px;
      line-height: 14px;
      @include letterSpacing(12px, 75);
    }
    @include tab {
      font-size: 13px;
      line-height: 16px;
      @include letterSpacing(13px, 75);
    }
    @include pc {
      font-size: 13px;
      line-height: 16px;
      @include letterSpacing(13px, 75);
    }
  }
  &__right__list[data-mode="thumb"] li .index__right__category br {
    display: none !important;
  }
  &__right__list[data-mode="thumb"] li .index__right__year {
    margin-left: 5px;
  }
  &__right__list[data-mode="thumb"] li h3 br {
    display: none !important;
  }

  &__right__list[data-mode="thumb"] a {
    color: #fff;
    transition: all .1s ease-out;
    &:hover {
      text-decoration: none;
    }
  }

  &__right__list[data-mode="thumb"] .index__right__thumb {
    transition: transform .2s ease-out;
    transform-origin: center center;
    transform: scale(1);
    position: relative;
    width: 100%;
    &:before {
      content:"";
      display: block;
      padding-top: calc( 189 / 344 * 100% );
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__right__list[data-mode="thumb"] li {
    /*
    opacity: 0;
    transition: opacity .2s ease-out;
    &.is_active {
      opacity: 1;
    }
    */
  }

  &__right__list[data-mode="thumb"] li h3 {
    margin-top: 10px;
    //margin-bottom: -10px;
  }

  &__right__list[data-mode="thumb"] li .index__right__category,
  &__right__list[data-mode="thumb"] li .index__right__year {
    //display: inline-block;
    float: left;
  }

  &__right__list[data-mode="thumb"] li {
    &.indexListColor--0:hover a,
    &.indexListColor--0:active a {
      //color: #FF0000;
      color: #ffff00;
    }
    &.indexListColor--0:hover:before,
    &.indexListColor--0:active:before,
    &.indexListColor--0:hover:after,
    &.indexListColor--0:active:after {
      //background: #FF0000;
      background: #ffff00;
    }

    &.indexListColor--1:hover a,
    &.indexListColor--1:active a {
      //color: #00FFFF;
      color: #ffff00;
    }
    &.indexListColor--1:hover:before,
    &.indexListColor--1:active:before,
    &.indexListColor--1:hover:after,
    &.indexListColor--1:active:after {
      //background: #00FFFF;
      background: #ffff00;
    }

    &.indexListColor--2:hover a,
    &.indexListColor--2:active a {
      //color: #007475;
      color: #ffff00;
    }
    &.indexListColor--2:hover:before,
    &.indexListColor--2:active:before,
    &.indexListColor--2:hover:after,
    &.indexListColor--2:active:after {
      //background: #007475;
      background: #ffff00;
    }

    &.indexListColor--3:hover a,
    &.indexListColor--3:active a {
      //color: #FFFF00;
      color: #ffff00;
    }
    &.indexListColor--3:hover:before,
    &.indexListColor--3:active:before,
    &.indexListColor--3:hover:after,
    &.indexListColor--3:active:after {
      //background: #FFFF00;
      background: #ffff00;
    }

    &.indexListColor--4:hover a,
    &.indexListColor--4:active a {
      //color: #FF9AFF;
      color: #ffff00;
    }
    &.indexListColor--4:hover:before,
    &.indexListColor--4:active:before,
    &.indexListColor--4:hover:after,
    &.indexListColor--4:active:after {
      //background: #FF9AFF;
      background: #ffff00;
    }

    &.indexListColor--5:hover a,
    &.indexListColor--5:active a {
      //color: #B3B3B3;
      color: #ffff00;
    }
    &.indexListColor--5:hover:before,
    &.indexListColor--5:active:before,
    &.indexListColor--5:hover:after,
    &.indexListColor--5:active:after {
      //background: #B3B3B3;
      background: #ffff00;
    }

    &.indexListColor--6:hover a,
    &.indexListColor--6:active a {
      //color: #C7B299;
      color: #ffff00;
    }
    &.indexListColor--6:hover:before,
    &.indexListColor--6:active:before,
    &.indexListColor--6:hover:after,
    &.indexListColor--6:active:after {
      //background: #C7B299;
      background: #ffff00;
    }

    &.indexListColor--7:hover a,
    &.indexListColor--7:active a {
      //color: #FF5A9F;
      color: #ffff00;
    }
    &.indexListColor--7:hover:before,
    &.indexListColor--7:active:before,
    &.indexListColor--7:hover:after,
    &.indexListColor--7:active:after {
      //background: #FF5A9F;
      background: #ffff00;
    }

    &.indexListColor--8:hover a,
    &.indexListColor--8:active a {
      //color: #66DFBF;
      color: #ffff00;
    }
    &.indexListColor--8:hover:before,
    &.indexListColor--8:active:before,
    &.indexListColor--8:hover:after ,
      &.indexListColor--8:active:after {
      //background: #66DFBF;
      background: #ffff00;
    }
  }

  &__right__list[data-mode="list"] > li:not(.post-categories) h3 {
    @extend .swiss721_extend_bold;
    letter-spacing: 0;
    margin-bottom: 0;
    padding-right: 14px;
    @include sp {
      font-size: 31px;
      line-height: 28px;
    }
    @include tab {
      font-size: 39px;
      line-height: 35px;
    }
    @include pc {
      width: calc( 100% - ( 100% / 3 ) - 28px );
      font-size: 39px;
      line-height: 35px;
    }
  }

  &__right__list[data-mode="list"] > li:not(.post-categories) .index__right__thumb {
    transition: all .2s ease-out;
    width: 0;
    height: 0;
    img {
      transition: transform .2s ease-out;
      transform-origin: center center;
      transform: scale(0);
    }
  }

  &__right__list[data-mode="list"] > li:not(.post-categories) .index__right__category,
  &__right__list[data-mode="list"] > li:not(.post-categories) .index__right__year {
    letter-spacing: 0;
    @include sp {
      float: left;
      line-height: 22px;
    }
    @include tab {
      float: left;
      line-height: 22px;
    }
  }

  &__right__list[data-mode="list"] > li:not(.post-categories) .index__right__year {
    margin-left: 5px;
  }

  &__right__list[data-mode="list"] > li:not(.post-categories) .index__right__category {
    padding-right: 0px;
    @include pc {
      width: calc( 50% - ( 100% / 4 ) - 10px );
    }
    & br {
      @include sp {
        display: none !important;
      }
      @include tab {
        display: none !important;
      }
    }
    & .post-categories {
      display: inline;
    }
    & .list-hide {
      @include pc {
        display: none;
      }
    }
  }

  &__right__list[data-mode="list"] a {
    color: #fff;
    transition: all .1s ease-out;
    @include pc {
      display: flex;
    }
    &:hover {
      text-decoration: none;
    }
  }

  &__right__list[data-mode="list"] > li:not(.post-categories) {
    list-style: none;
    padding: 7px 0;
    border-bottom: 1px solid #fff;
    position: relative;
    //transform: translateX(100%);
    //transition: transform .75s cubic-bezier(0.19, 1, 0.22, 1),
                //background .75s cubic-bezier(0.19, 1, 0.22, 1),
                //color .75s cubic-bezier(0.19, 1, 0.22, 1),
                //height .75s cubic-bezier(0.19, 1, 0.22, 1),
                //padding .75s cubic-bezier(0.19, 1, 0.22, 1);
    @include sp {
      padding-left: 56px;
      padding-right: 14px;
    }
    @include tab {
      padding-left: 44px;
      padding-right: 14px;
    }
    @include pc {
      padding-left: 44px;
      padding-right: 14px;
    }
    &.is_active {
      transform: translateX(0);
    }
    &:before, &:after {
    //&:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 5px;
      margin-top: -2px;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform .3s ease-out;
    }
    &:hover:before,
    &:active:before,
    &:hover:after,
    &:active:after {
      transform: scaleX(1);
    }
    &:before {
      top: -1px;
    }
    &:after {
      top: 100%;
    }
    &.indexListColor--0:hover a,
    &.indexListColor--0:active a {
      //color: #FF0000;
      color: #ffff00;
    }
    &.indexListColor--0:hover:before,
    &.indexListColor--0:active:before,
    &.indexListColor--0:hover:after,
    &.indexListColor--0:active:after {
      //background: #FF0000;
      background: #ffff00;
    }

    &.indexListColor--1:hover a,
    &.indexListColor--1:active a {
      //color: #00FFFF;
      color: #ffff00;
    }
    &.indexListColor--1:hover:before,
    &.indexListColor--1:active:before,
    &.indexListColor--1:hover:after,
    &.indexListColor--1:active:after {
      //background: #00FFFF;
      background: #ffff00;
    }

    &.indexListColor--2:hover a,
    &.indexListColor--2:active a {
      //color: #007475;
      color: #ffff00;
    }
    &.indexListColor--2:hover:before,
    &.indexListColor--2:active:before,
    &.indexListColor--2:hover:after,
    &.indexListColor--2:active:after {
      //background: #007475;
      background: #ffff00;
    }

    &.indexListColor--3:hover a,
    &.indexListColor--3:active a {
      //color: #FFFF00;
      color: #ffff00;
    }
    &.indexListColor--3:hover:before,
    &.indexListColor--3:active:before,
    &.indexListColor--3:hover:after,
    &.indexListColor--3:active:after {
      //background: #FFFF00;
      background: #ffff00;
    }

    &.indexListColor--4:hover a,
    &.indexListColor--4:active a {
      //color: #FF9AFF;
      color: #ffff00;
    }
    &.indexListColor--4:hover:before,
    &.indexListColor--4:active:before,
    &.indexListColor--4:hover:after,
    &.indexListColor--4:active:after {
      //background: #FF9AFF;
      background: #ffff00;
    }

    &.indexListColor--5:hover a,
    &.indexListColor--5:active a {
      //color: #B3B3B3;
      color: #ffff00;
    }
    &.indexListColor--5:hover:before,
    &.indexListColor--5:active:before,
    &.indexListColor--5:hover:after,
    &.indexListColor--5:active:after {
      //background: #B3B3B3;
      background: #ffff00;
    }

    &.indexListColor--6:hover a,
    &.indexListColor--6:active a {
      //color: #C7B299;
      color: #ffff00;
    }
    &.indexListColor--6:hover:before,
    &.indexListColor--6:active:before,
    &.indexListColor--6:hover:after,
    &.indexListColor--6:active:after {
      //background: #C7B299;
      background: #ffff00;
    }

    &.indexListColor--7:hover a,
    &.indexListColor--7:active a {
      //color: #FF5A9F;
      color: #ffff00;
    }
    &.indexListColor--7:hover:before,
    &.indexListColor--7:active:before,
    &.indexListColor--7:hover:after,
    &.indexListColor--7:active:after {
      //background: #FF5A9F;
      background: #ffff00;
    }

    &.indexListColor--8:hover a,
    &.indexListColor--8:active a {
      //color: #66DFBF;
      color: #ffff00;
    }
    &.indexListColor--8:hover:before,
    &.indexListColor--8:active:before,
    &.indexListColor--8:hover:after,
    &.indexListColor--8:active:after {
      //background: #66DFBF;
      background: #ffff00;
    }

  }

  &__right__list {
    hyphens: auto;
    padding-top: 2.5px;
  }
}


.left.min-width {

  .index__left__list li a {
    @include pc {
      display: block;
    }
  }

  .index__left__date {
    @include pc {
      width: 100%;
    }
  }

  .index__left h3 {
    @include pc {
      width: 100%;
      padding-right: 14px;
      padding-left: 14px;
    }
  }

}


.right.min-width {

  .min-size-none {
    @include pc {
      display: none !important;
    }
  }

  .index__right__list[data-mode="thumb"] {
    @include pc {
      padding: 28px 14px 28px 44px;
    }
  }

  .index__right__list[data-mode="thumb"] li {
    @include pc {
      width: calc( 100% - 28px);
      margin: 0 14px 18px;
    }
  }

  .index__right__list[data-mode="thumb"] li h3,
  .index__right__list[data-mode="thumb"] li .index__right__category,
  .index__right__list[data-mode="thumb"] li .index__right__year {
    @include pc {
      letter-spacing: 0;
    }
  }

  .index__right__list[data-mode="list"] a {
    @include pc {
      display: block;
    }
  }

  .index__right__list[data-mode="list"] > li:not(.post-categories) h3 {
    @include pc {
      width: 100%;
    }
  }

  .index__right__list[data-mode="list"] > li:not(.post-categories) .index__right__category,
  .index__right__list[data-mode="list"] > li:not(.post-categories) .index__right__year {
    @include pc {
      width: auto;
      float: left;
      line-height: 22px;
    }
  }

  .index__right__list[data-mode="list"] > li:not(.post-categories) .index__right__category {
    & br {
      @include pc {
        display: none !important;
      }
    }
  }

  .index__right__list[data-mode="list"] > li:not(.post-categories) {
    @include pc {
      padding-left: 42px;
      padding-right: 14px;
    }
  }

}
