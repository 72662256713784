.contact {
  box-sizing: border-box;
  position: fixed;
  left: 0;
  padding-top: 13px;
  padding-right: 44px;
  height: 100%;
  background: #{$white-bg};
  transform: translateX(-100%);
  transition: all .75s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 5000;
  overflow-y: scroll;
  //-webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  @include sp {
    width: 100%;
    padding-bottom: 56px;
    margin-top: 1px;
    top: 40px;
  }
  @include tab {
    width: 50vw;
    top: 50px;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  @include pc {
    width: 50vw;
    top: 50px;
    &.max-width {
      width: calc( 100% - #{$min-width} );
    }
    &.min-width {
      width: #{$min-width};
    }
  }
  &.is_active {
    transform: translateX(0);
  }
}

.contact__table {
  padding-bottom: 56px;
}

.contact__tr,
.contact__tr a {
  @extend .helvetica_neue_medium;
  display: flex;
  font-size: 19px;
  line-height: 28px;
  @include letterSpacing(19px, 50);
}

.contact__tr {
  margin-bottom: 20px;
}

.contact__tr a {
  width: 100%;
  color: #000;
  transition: all .1s ease-out;
  &:hover,
  &:active {
    color: #0000FF;
    text-decoration: none;
  }
}

.contact__tr {
  @include sp {
    padding: 0 14px 0 24px;
  }
  @include tab {
    padding: 0 14px;
  }
  @include pc {
    padding: 0 0 0 14px;
  }
  dt {
    width: 25%;
    @include sp {
      letter-spacing: 0;
    }
    span.pc_only {
      @include tab {
        display: inline !important;
      }
      @include pc {
        display: inline !important;
      }
    }
  }
  dd {
    width: calc( 100% / 4 * 3);
    padding-right: 10px;
    @include sp {
      line-height: 24px;
      letter-spacing: 0;
    }
    @include tab {
      padding-left: 8px;
    }
    @include pc {
      padding-left: 8px;
    }
    .map {
      position: relative;
      width: 100%;
      margin-top: 6px;
      @include sp {
        letter-spacing: 0;
      }
      @include tab {
        margin-bottom: 17px;
      }
      @include pc {
        margin-bottom: 17px;
      }
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      iframe {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
      }
    }
  }
}

.contact__social {
  margin-bottom: 2px;
}
